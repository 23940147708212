import md5 from 'md5'

const OSS = require('ali-oss')


const client = new OSS({
  cname: true,
  secure: true,
  endpoint: 'oss.rashsjfw.com',
  bucket: 'bless-mall',
  region: 'oss-cn-shenzhen',
  accessKeyId: 'LTAI4G2LQbMU73FDP7mmhcun',
  accessKeySecret: 'KQ0tbra2p3phH23hAlgFNuJ83ZI42c'
})

export function filename(file) {
  const splits = file.name.split('.')
  const extension = splits[splits.length - 1]
  if (file.name === extension) {
    return md5(file.uid)
  } else {
    return md5(file.uid) + '.' + extension
  }
}

export default client